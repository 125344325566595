import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Automatic retry";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Warning = makeShortcode("Warning");
const Tip = makeShortcode("Tip");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "automatic-retry",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#automatic-retry",
        "aria-label": "automatic retry permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Automatic retry`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#retryingclient"
        }}>{`RetryingClient`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#retryrule"
        }}>{`RetryRule`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#backoff"
        }}>{`Backoff`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#maxtotalattempts-vs-per-backoff-maxattempts"
        }}>{`maxTotalAttempts vs per-Backoff maxAttempts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#per-attempt-timeout"
        }}>{`Per-attempt timeout`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#retryingclient-with-logging"
        }}>{`RetryingClient with logging`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#retryingclient-with-circuit-breaker"
        }}>{`RetryingClient with circuit breaker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#see-also"
        }}>{`See also`}</a></li>
    </ul>
    <p>{`When a client gets an error response, it might want to retry the request depending on the response.
This can be accomplished using a `}<a parentName="p" {...{
        "href": "/docs/client-decorator"
      }}>{`decorator`}</a>{`, and Armeria provides the following
implementations out-of-the box.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
        }}>{`type://RetryingClient`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://RetryingRpcClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingRpcClient.html"
        }}>{`type://RetryingRpcClient`}</a></li>
    </ul>
    <p>{`Both behave the same except for the different request and response types.
So, let's find out what we can do with `}<a parentName="p" {...{
        "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
      }}>{`type://RetryingClient`}</a>{`.`}</p>
    <h2 {...{
      "id": "retryingclient",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#retryingclient",
        "aria-label": "retryingclient permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h2">{`RetryingClient`}</inlineCode></h2>
    <p>{`You can just use the `}<inlineCode parentName="p">{`decorator()`}</inlineCode>{` method in `}<a parentName="p" {...{
        "href": "type://ClientBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientBuilder.html"
      }}>{`type://ClientBuilder`}</a>{` or `}<a parentName="p" {...{
        "href": "type://WebClientBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClientBuilder.html"
      }}>{`type://WebClientBuilder`}</a>{` to build a
`}<a parentName="p" {...{
        "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
      }}>{`type://RetryingClient`}</a>{`. For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.WebClient;
import com.linecorp.armeria.client.retry.RetryingClient;
import com.linecorp.armeria.client.retry.RetryRule;
import com.linecorp.armeria.common.AggregatedHttpResponse;

RetryRule rule = RetryRule.failsafe();
WebClient client = WebClient.builder("http://example.com/hello")
                            .decorator(RetryingClient.newDecorator(rule))
                            .build();

AggregatedHttpResponse res = client.execute(...).aggregate().join();
`}</code></pre>
    <p>{`That's it. The client will keep attempting until it succeeds or the number of attempts exceeds the maximum
number of total attempts. You can configure the `}<inlineCode parentName="p">{`maxTotalAttempts`}</inlineCode>{` when making the decorator using
`}<a parentName="p" {...{
        "href": "type://RetryingClient#newDecorator(RetryRule,int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html#newDecorator(com.linecorp.armeria.client.retry.RetryRule,int)"
      }}>{`type://RetryingClient#newDecorator(RetryRule,int)`}</a>{`. Meanwhile, the `}<inlineCode parentName="p">{`rule`}</inlineCode>{` will decide to
retry depending on the response. In this case, the client retries when it receives `}<inlineCode parentName="p">{`5xx`}</inlineCode>{` response error or
an exception is raised.`}</p>
    <h2 {...{
      "id": "retryrule",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#retryrule",
        "aria-label": "retryrule permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h2">{`RetryRule`}</inlineCode></h2>
    <p>{`You can fluently build your own `}<a parentName="p" {...{
        "href": "type://RetryRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRule.html"
      }}>{`type://RetryRule`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.ResponseTimeoutException;
import com.linecorp.armeria.common.HttpStatus;

Backoff myBackoff = ...;
RetryRule.of(RetryRule.builder().onUnProcessed().thenBackoff(myBackoff),
             RetryRule.builder().onException(ResponseTimeoutException.class).thenBackoff(),
             RetryRule.builder().onStatus(HttpStatus.TOO_MANY_REQUESTS).thenNoRetry())
`}</code></pre>
    <p>{`Or you can customize the `}<strong parentName="p">{`rule`}</strong>{` by implementing `}<a parentName="p" {...{
        "href": "type://RetryRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRule.html"
      }}>{`type://RetryRule`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.ClientRequestContext;
import com.linecorp.armeria.client.UnprocessedRequestException;
import com.linecorp.armeria.client.retry.Backoff;
import com.linecorp.armeria.client.retry.RetryDecision;
import com.linecorp.armeria.common.ResponseHeaders;
import com.linecorp.armeria.common.logging.RequestLogProperty;

new RetryRule() {
    Backoff backoff = Backoff.ofDefault();

    @Override
    public CompletionStage<RetryDecision> shouldRetry(ClientRequestContext ctx,
                                                      @Nullable Throwable cause) {
        if (cause != null) {
            if (cause instanceof ResponseTimeoutException ||
                cause instanceof UnprocessedRequestException) {
                // The response timed out or the request has not been handled
                // by the server.
                return UnmodifiableFuture.completedFuture(RetryDecision.retry(backoff));
            }
        }

        ResponseHeaders responseHeaders = ctx.log().ensureAvailable(RequestLogProperty.RESPONSE_HEADERS)
                                             .responseHeaders();
        if (responseHeaders.status() == HttpStatus.TOO_MANY_REQUESTS) {
            return UnmodifiableFuture.completedFuture(RetryDecision.stop());
        }

        // Return 'next()' to lookup other rules.
        return UnmodifiableFuture.completedFuture(RetryDecision.next());
    }
};
`}</code></pre>
    <p>{`This will retry when one of `}<a parentName="p" {...{
        "href": "type://ResponseTimeoutException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ResponseTimeoutException.html"
      }}>{`type://ResponseTimeoutException`}</a>{` and `}<a parentName="p" {...{
        "href": "type://UnprocessedRequestException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/UnprocessedRequestException.html"
      }}>{`type://UnprocessedRequestException`}</a>{` is raised.
However, if the response's status is `}<inlineCode parentName="p">{`429 Too Many Requests`}</inlineCode>{`, it will stop retrying.
For all other cases, it will defer to the next rule if one exists.`}</p>
    <Warning mdxType="Warning">
      <p>{`We declare a `}<a parentName="p" {...{
          "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
        }}>{`type://Backoff`}</a>{` as a member and reuse it when a `}<inlineCode parentName="p">{`rule`}</inlineCode>{` returns it, so that we do not
return a different `}<a parentName="p" {...{
          "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
        }}>{`type://Backoff`}</a>{` instance for each `}<inlineCode parentName="p">{`shouldRetry()`}</inlineCode>{`. `}<a parentName="p" {...{
          "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
        }}>{`type://RetryingClient`}</a>{`
internally tracks the reference of the returned `}<a parentName="p" {...{
          "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
        }}>{`type://Backoff`}</a>{` and increases the counter that keeps
the number of attempts made so far, and resets it to 0 when the `}<a parentName="p" {...{
          "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
        }}>{`type://Backoff`}</a>{` returned by the retry rule
is not the same as before. Therefore, it is important to return the same `}<a parentName="p" {...{
          "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
        }}>{`type://Backoff`}</a>{` instance unless
you decided to change your `}<a parentName="p" {...{
          "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
        }}>{`type://Backoff`}</a>{` strategy. If you do not return the same one, when the
`}<a parentName="p" {...{
          "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
        }}>{`type://Backoff`}</a>{` yields a different delay based on the number of retries, such as an exponential backoff,
it will not work as expected. We will take a close look into a `}<a parentName="p" {...{
          "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
        }}>{`type://Backoff`}</a>{` at the next section.`}</p>
    </Warning>
    <Tip mdxType="Tip">
      <p><a parentName="p" {...{
          "href": "type://UnprocessedRequestException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/UnprocessedRequestException.html"
        }}>{`type://UnprocessedRequestException`}</a>{` literally means that the request has not been processed by the server.
Therefore, you can safely retry the request without worrying about the idempotency of the request.
For more information about idempotency, please refer to
`}<a parentName="p" {...{
          "href": "http://restcookbook.com/HTTP%20Methods/idempotency/"
        }}>{`What are idempotent and/or safe methods?`}</a>{`.`}</p>
    </Tip>
    <p>{`You can return a different `}<a parentName="p" {...{
        "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
      }}>{`type://Backoff`}</a>{` according to the response status.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.HttpStatusClass;

Backoff backoffOnServerErrorOrTimeout = Backoff.ofDefault();
Backoff backoffOnConflict = Backoff.fixed(100);
RetryRule.builder()
         .onException(ex -> ex instanceof ResponseTimeoutException ||
                            ex instanceof UnprocessedRequestException)
         .thenBackoff(backoffOnServerErrorOrTimeout)
         .orElse(RetryRule.builder()
                          .onStatusClass(HttpStatusClass.SERVER_ERROR)
                          .thenBackoff(backoffOnServerErrorOrTimeout))
         .orElse(RetryRule.builder()
                          .onStatus(HttpStatus.CONFLICT)
                          .thenBackoff(backoffOnConflict));
`}</code></pre>
    <p>{`If you need to determine whether you need to retry by looking into the response content, you should implement
`}<a parentName="p" {...{
        "href": "type://RetryRuleWithContent:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRuleWithContent.html"
      }}>{`type://RetryRuleWithContent`}</a>{` and specify it when you create a `}<a parentName="p" {...{
        "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
      }}>{`type://WebClient`}</a>{`
using `}<a parentName="p" {...{
        "href": "type://RetryingClientBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClientBuilder.html"
      }}>{`type://RetryingClientBuilder`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.retry.RetryRuleWithContent;

RetryRuleWithContent<HttpResponse> retryRule =
        RetryRuleWithContent
                .<HttpResponse>builder()
                .onException(ex -> ex instanceof ResponseTimeoutException ||
                                   ex instanceof UnprocessedRequestException)
                .onResponse(response -> {
                    return response.aggregate()
                                   .thenApply(content -> "Should I retry?".equals(content.contentUtf8()));
                })
                .thenBackoff(backoff);

// Create a WebClient with a retry rule.
WebClient client = WebClient
        .builder(...)
        .decorator(RetryingClient.builder(retryRule)
                                 .newDecorator())
        .build();

AggregatedHttpResponse res = client.execute(...).aggregate().join();
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`You might find the `}<a parentName="p" {...{
          "href": "type://Exceptions#peel(Throwable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/Exceptions.html#peel(java.lang.Throwable)"
        }}>{`type://Exceptions#peel(Throwable)`}</a>{` method useful when the exception you are trying to
handle is wrapped by exceptions like `}<inlineCode parentName="p">{`CompletionException`}</inlineCode>{` and `}<inlineCode parentName="p">{`ExecutionException`}</inlineCode>{`:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-java"
        }}>{`import com.linecorp.armeria.common.Exceptions;

@Override
public CompletionStage<RetryDecision> shouldRetry(ClientRequestContext ctx,
                                                  @Nullable Throwable cause) {
    if (cause != null) {
        if (cause instanceof ResponseTimeoutException ||
            cause instanceof UnprocessedRequestException) {
            // The response timed out or the request has not been handled
            // by the server.
            return UnmodifiableFuture.completedFuture(backoff);
        }

        Throwable peeled = Exceptions.peel(cause);
        if (peeled instanceof MyException) { ... }
    }
    ...
}
`}</code></pre>
    </Tip>
    <h2 {...{
      "id": "backoff",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#backoff",
        "aria-label": "backoff permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h2">{`Backoff`}</inlineCode></h2>
    <p>{`You can use a `}<a parentName="p" {...{
        "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
      }}>{`type://Backoff`}</a>{` to determine the delay between attempts. Armeria provides `}<a parentName="p" {...{
        "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
      }}>{`type://Backoff`}</a>{`
implementations which produce the following delays out of the box:`}</p>
    <ul>
      <li parentName="ul">{`Fixed delay, created with `}<a parentName="li" {...{
          "href": "type://Backoff#fixed(long):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html#fixed(long)"
        }}>{`type://Backoff#fixed(long)`}</a></li>
      <li parentName="ul">{`Random delay, created with `}<a parentName="li" {...{
          "href": "type://Backoff#random(long,long):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html#random(long,long)"
        }}>{`type://Backoff#random(long,long)`}</a></li>
      <li parentName="ul">{`Exponential delay which is multiplied on each attempt, created with `}<a parentName="li" {...{
          "href": "type://Backoff#exponential(long,long):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html#exponential(long,long)"
        }}>{`type://Backoff#exponential(long,long)`}</a></li>
    </ul>
    <p>{`Armeria provides `}<a parentName="p" {...{
        "href": "type://Backoff#ofDefault():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html#ofDefault()"
      }}>{`type://Backoff#ofDefault()`}</a>{` that you might use by default. It is exactly the same as:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`Backoff.exponential(200   /* minDelayMillis */,
                    10000 /* maxDelayMillis */,
                    2.0   /* multiplier     */)
       .withJitter(0.2 /* jitterRate */);
`}</code></pre>
    <p>{`The delay starts from `}<inlineCode parentName="p">{`minDelayMillis`}</inlineCode>{` until it reaches `}<inlineCode parentName="p">{`maxDelayMillis`}</inlineCode>{` multiplying by multiplier every
retry. Please note that the `}<a parentName="p" {...{
        "href": "type://Backoff#withJitter(double):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html#withJitter(double)"
      }}>{`type://Backoff#withJitter(double)`}</a>{` will add jitter value to the calculated delay.`}</p>
    <p>{`For more information, please refer to the API documentation of the
`}<a parentName="p" {...{
        "href": "https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/package-summary.html"
      }}>{`com.linecorp.armeria.client.retry`}</a>{` package.`}</p>
    <h2 {...{
      "id": "maxtotalattempts-vs-per-backoff-maxattempts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#maxtotalattempts-vs-per-backoff-maxattempts",
        "aria-label": "maxtotalattempts vs per backoff maxattempts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h2">{`maxTotalAttempts`}</inlineCode>{` vs per-Backoff `}<inlineCode parentName="h2">{`maxAttempts`}</inlineCode></h2>
    <p>{`If you create a `}<a parentName="p" {...{
        "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
      }}>{`type://Backoff`}</a>{` using `}<a parentName="p" {...{
        "href": "type://Backoff#withMaxAttempts(int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html#withMaxAttempts(int)"
      }}>{`type://Backoff#withMaxAttempts(int)`}</a>{` in a `}<a parentName="p" {...{
        "href": "type://RetryRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRule.html"
      }}>{`type://RetryRule`}</a>{`,
the `}<a parentName="p" {...{
        "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
      }}>{`type://RetryingClient`}</a>{` which uses the `}<a parentName="p" {...{
        "href": "type://RetryRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRule.html"
      }}>{`type://RetryRule`}</a>{` will stop retrying when the number of
attempts passed `}<inlineCode parentName="p">{`maxAttempts`}</inlineCode>{`. However, if you have more than one `}<a parentName="p" {...{
        "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
      }}>{`type://Backoff`}</a>{` and return one after
the other continuously, it will keep retrying over and over again because the counter that
`}<a parentName="p" {...{
        "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
      }}>{`type://RetryingClient`}</a>{` internally tracks is initialized every time the different `}<a parentName="p" {...{
        "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
      }}>{`type://Backoff`}</a>{` is
returned. To limit the number of attempts in a whole retry session, `}<a parentName="p" {...{
        "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
      }}>{`type://RetryingClient`}</a>{` limits
the maximum number of total attempts to 10 by default. You can change this value by specifying
`}<inlineCode parentName="p">{`maxTotalAttempts`}</inlineCode>{` when you build a `}<a parentName="p" {...{
        "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
      }}>{`type://RetryingClient`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`RetryingClient.newDecorator(rule, maxTotalAttempts);
`}</code></pre>
    <p>{`Or, you can override the default value of 10 using the JVM system property
`}<inlineCode parentName="p">{`-Dcom.linecorp.armeria.defaultMaxTotalAttempts=<integer>`}</inlineCode>{`.`}</p>
    <p>{`Note that when a `}<a parentName="p" {...{
        "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
      }}>{`type://RetryingClient`}</a>{` stops due to the attempts limit, the client will get the last received
`}<a parentName="p" {...{
        "href": "type://Response:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Response.html"
      }}>{`type://Response`}</a>{` from the server.`}</p>
    <h2 {...{
      "id": "per-attempt-timeout",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#per-attempt-timeout",
        "aria-label": "per attempt timeout permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Per-attempt timeout`}</h2>
    <p><a parentName="p" {...{
        "href": "type://ResponseTimeoutException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ResponseTimeoutException.html"
      }}>{`type://ResponseTimeoutException`}</a>{` can occur in two different situations while retrying. First, it occurs
when the time of whole retry session has passed the time previously configured using:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ClientBuilder.responseTimeoutMillis(millis);
// or..
ClientRequestContext.setResponseTimeoutAfterMillis(millis);
`}</code></pre>
    <p>{`You cannot retry on this `}<a parentName="p" {...{
        "href": "type://ResponseTimeoutException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ResponseTimeoutException.html"
      }}>{`type://ResponseTimeoutException`}</a>{`.
Second, it occurs when the time of individual attempt in retry has passed the time which is per-attempt timeout.
You can configure it when you create the decorator:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`RetryingClient.newDecorator(rule, maxTotalAttempts,
                            responseTimeoutMillisForEachAttempt);
`}</code></pre>
    <p>{`You can retry on this `}<a parentName="p" {...{
        "href": "type://ResponseTimeoutException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ResponseTimeoutException.html"
      }}>{`type://ResponseTimeoutException`}</a>{`.`}</p>
    <p>{`For example, when making a retrying request to an unresponsive service
with `}<inlineCode parentName="p">{`responseTimeoutMillis = 10,000`}</inlineCode>{`, `}<inlineCode parentName="p">{`responseTimeoutMillisForEachAttempt = 3,000`}</inlineCode>{` and disabled
`}<a parentName="p" {...{
        "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
      }}>{`type://Backoff`}</a>{`, the first three attempts will be timed out by the per-attempt timeout (3,000ms).
The 4th one will be aborted after 1,000ms since the request session has reached at 10,000ms before
it is timed out by the per-attempt timeout.`}</p>
    <span {...{
      "className": "remark-draw remark-draw-bob-svg"
    }}><svg parentName="span" {...{
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "600",
        "height": "128",
        "className": "svgbob"
      }}>{`
  `}<style parentName="svg">{`.svgbob line, .svgbob path, .svgbob circle, .svgbob rect, .svgbob polygon {
  stroke: black;
  stroke-width: 2;
  stroke-opacity: 1;
  fill-opacity: 1;
  stroke-linecap: round;
  stroke-linejoin: miter;
}

.svgbob text {
  white-space: pre;
  fill: black;
  font-family: Iosevka Fixed, monospace;
  font-size: 14px;
}

.svgbob rect.backdrop {
  stroke: none;
  fill: white;
}

.svgbob .broken {
  stroke-dasharray: 8;
}

.svgbob .filled {
  fill: black;
}

.svgbob .bg_filled {
  fill: white;
  stroke-width: 1;
}

.svgbob .nofill {
  fill: white;
}

.svgbob .end_marked_arrow {
  marker-end: url(#arrow);
}

.svgbob .start_marked_arrow {
  marker-start: url(#arrow);
}

.svgbob .end_marked_diamond {
  marker-end: url(#diamond);
}

.svgbob .start_marked_diamond {
  marker-start: url(#diamond);
}

.svgbob .end_marked_circle {
  marker-end: url(#circle);
}

.svgbob .start_marked_circle {
  marker-start: url(#circle);
}

.svgbob .end_marked_open_circle {
  marker-end: url(#open_circle);
}

.svgbob .start_marked_open_circle {
  marker-start: url(#open_circle);
}

.svgbob .end_marked_big_open_circle {
  marker-end: url(#big_open_circle);
}

.svgbob .start_marked_big_open_circle {
  marker-start: url(#big_open_circle);
}

.remark-draw-bob-svg * {
            font-family: Hack;
            font-size: 13px;
          }
          .remark-draw-bob-svg rect.backdrop,
          .remark-draw-bob-svg .nofill {
            fill: none;
          }
          `}</style>{`
  `}<defs parentName="svg">{`
    `}<marker parentName="defs" {...{
            "id": "arrow",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,0 0,4 4,2 0,0"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "diamond",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,2 2,0 4,2 2,4 0,2"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "big_open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "3",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
  `}</defs>{`
  `}<rect parentName="svg" {...{
          "className": "backdrop",
          "x": "0",
          "y": "0",
          "width": "600",
          "height": "128"
        }}></rect>{`
  `}<text parentName="svg" {...{
          "x": "2",
          "y": "12"
        }}>{`0ms`}</text>{`
  `}<text parentName="svg" {...{
          "x": "98",
          "y": "12"
        }}>{`3,000ms`}</text>{`
  `}<text parentName="svg" {...{
          "x": "194",
          "y": "12"
        }}>{`6,000ms`}</text>{`
  `}<text parentName="svg" {...{
          "x": "290",
          "y": "12"
        }}>{`9,000ms`}</text>{`
  `}<text parentName="svg" {...{
          "x": "18",
          "y": "60"
        }}>{`Attempt`}</text>{`
  `}<text parentName="svg" {...{
          "x": "82",
          "y": "60"
        }}>{`1`}</text>{`
  `}<text parentName="svg" {...{
          "x": "114",
          "y": "60"
        }}>{`Attempt`}</text>{`
  `}<text parentName="svg" {...{
          "x": "178",
          "y": "60"
        }}>{`2`}</text>{`
  `}<text parentName="svg" {...{
          "x": "210",
          "y": "60"
        }}>{`Attempt`}</text>{`
  `}<text parentName="svg" {...{
          "x": "274",
          "y": "60"
        }}>{`3`}</text>{`
  `}<text parentName="svg" {...{
          "x": "306",
          "y": "60"
        }}>{`A4`}</text>{`
  `}<text parentName="svg" {...{
          "x": "314",
          "y": "108"
        }}>{`10,000ms`}</text>{`
  `}<path parentName="svg" {...{
          "d": "M 392,96 A 16,16 0,0,0 392,112",
          "className": "nofill"
        }}></path>{`
  `}<text parentName="svg" {...{
          "x": "394",
          "y": "108"
        }}>{`ResponseTimeoutException`}</text>{`
  `}<path parentName="svg" {...{
          "d": "M 584,96 A 16,16 0,0,1 584,112",
          "className": "nofill"
        }}></path>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "16",
            "x2": "4",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "40",
            "x2": "332",
            "y2": "40",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "332",
            "y1": "40",
            "x2": "332",
            "y2": "96",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "100",
            "y1": "16",
            "x2": "100",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "196",
            "y1": "16",
            "x2": "196",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "292",
            "y1": "16",
            "x2": "292",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "72",
            "x2": "332",
            "y2": "72",
            "className": "solid"
          }}></line>{`
  `}</g>
      </svg>
    </span>
    <p>{`In the example above, every attempt is made before it is timed out because the `}<a parentName="p" {...{
        "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
      }}>{`type://Backoff`}</a>{` is disabled.
However, what if a `}<a parentName="p" {...{
        "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
      }}>{`type://Backoff`}</a>{` is enabled and the moment of trying next attempt is after the point of
`}<a parentName="p" {...{
        "href": "type://ResponseTimeoutException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ResponseTimeoutException.html"
      }}>{`type://ResponseTimeoutException`}</a>{`? In such a case, the `}<a parentName="p" {...{
        "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
      }}>{`type://RetryingClient`}</a>{` does not schedule for the
next attempt, but finishes the retry session immediately with the last received `}<a parentName="p" {...{
        "href": "type://Response:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Response.html"
      }}>{`type://Response`}</a>{`.
Consider the following example:`}</p>
    <span {...{
      "className": "remark-draw remark-draw-bob-svg"
    }}><svg parentName="span" {...{
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "592",
        "height": "160",
        "className": "svgbob"
      }}>{`
  `}<style parentName="svg">{`.svgbob line, .svgbob path, .svgbob circle, .svgbob rect, .svgbob polygon {
  stroke: black;
  stroke-width: 2;
  stroke-opacity: 1;
  fill-opacity: 1;
  stroke-linecap: round;
  stroke-linejoin: miter;
}

.svgbob text {
  white-space: pre;
  fill: black;
  font-family: Iosevka Fixed, monospace;
  font-size: 14px;
}

.svgbob rect.backdrop {
  stroke: none;
  fill: white;
}

.svgbob .broken {
  stroke-dasharray: 8;
}

.svgbob .filled {
  fill: black;
}

.svgbob .bg_filled {
  fill: white;
  stroke-width: 1;
}

.svgbob .nofill {
  fill: white;
}

.svgbob .end_marked_arrow {
  marker-end: url(#arrow);
}

.svgbob .start_marked_arrow {
  marker-start: url(#arrow);
}

.svgbob .end_marked_diamond {
  marker-end: url(#diamond);
}

.svgbob .start_marked_diamond {
  marker-start: url(#diamond);
}

.svgbob .end_marked_circle {
  marker-end: url(#circle);
}

.svgbob .start_marked_circle {
  marker-start: url(#circle);
}

.svgbob .end_marked_open_circle {
  marker-end: url(#open_circle);
}

.svgbob .start_marked_open_circle {
  marker-start: url(#open_circle);
}

.svgbob .end_marked_big_open_circle {
  marker-end: url(#big_open_circle);
}

.svgbob .start_marked_big_open_circle {
  marker-start: url(#big_open_circle);
}

.remark-draw-bob-svg * {
            font-family: Hack;
            font-size: 13px;
          }
          .remark-draw-bob-svg rect.backdrop,
          .remark-draw-bob-svg .nofill {
            fill: none;
          }
          `}</style>{`
  `}<defs parentName="svg">{`
    `}<marker parentName="defs" {...{
            "id": "arrow",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,0 0,4 4,2 0,0"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "diamond",
            "viewBox": "-2 -2 8 8",
            "refX": "4",
            "refY": "2",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<polygon parentName="marker" {...{
              "points": "0,2 2,0 4,2 2,4 0,2"
            }}></polygon>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "2",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
    `}<marker parentName="defs" {...{
            "id": "big_open_circle",
            "viewBox": "0 0 8 8",
            "refX": "4",
            "refY": "4",
            "markerWidth": "7",
            "markerHeight": "7",
            "orient": "auto-start-reverse"
          }}>{`
      `}<circle parentName="marker" {...{
              "cx": "4",
              "cy": "4",
              "r": "3",
              "className": "bg_filled"
            }}></circle>{`
    `}</marker>{`
  `}</defs>{`
  `}<rect parentName="svg" {...{
          "className": "backdrop",
          "x": "0",
          "y": "0",
          "width": "592",
          "height": "160"
        }}></rect>{`
  `}<text parentName="svg" {...{
          "x": "2",
          "y": "12"
        }}>{`0ms`}</text>{`
  `}<text parentName="svg" {...{
          "x": "98",
          "y": "12"
        }}>{`3,000ms`}</text>{`
  `}<text parentName="svg" {...{
          "x": "194",
          "y": "12"
        }}>{`6,000ms`}</text>{`
  `}<text parentName="svg" {...{
          "x": "290",
          "y": "12"
        }}>{`9,000ms`}</text>{`
  `}<text parentName="svg" {...{
          "x": "386",
          "y": "12"
        }}>{`12,000ms`}</text>{`
  `}<line parentName="svg" {...{
          "x1": "292",
          "y1": "16",
          "x2": "292",
          "y2": "128",
          "className": "solid"
        }}></line>{`
  `}<text parentName="svg" {...{
          "x": "18",
          "y": "60"
        }}>{`Attempt`}</text>{`
  `}<text parentName="svg" {...{
          "x": "82",
          "y": "60"
        }}>{`1`}</text>{`
  `}<text parentName="svg" {...{
          "x": "210",
          "y": "60"
        }}>{`Attempt`}</text>{`
  `}<text parentName="svg" {...{
          "x": "274",
          "y": "60"
        }}>{`2`}</text>{`
  `}<text parentName="svg" {...{
          "x": "402",
          "y": "60"
        }}>{`Attempt`}</text>{`
  `}<text parentName="svg" {...{
          "x": "466",
          "y": "60"
        }}>{`3`}</text>{`
  `}<text parentName="svg" {...{
          "x": "482",
          "y": "60"
        }}>{`is`}</text>{`
  `}<text parentName="svg" {...{
          "x": "506",
          "y": "60"
        }}>{`not`}</text>{`
  `}<text parentName="svg" {...{
          "x": "538",
          "y": "60"
        }}>{`made`}</text>{`
  `}<text parentName="svg" {...{
          "x": "306",
          "y": "108"
        }}>{`10,000ms`}</text>{`
  `}<text parentName="svg" {...{
          "x": "258",
          "y": "140"
        }}>{`stops`}</text>{`
  `}<path parentName="svg" {...{
          "d": "M 384,96 A 16,16 0,0,0 384,112",
          "className": "nofill"
        }}></path>{`
  `}<text parentName="svg" {...{
          "x": "386",
          "y": "108"
        }}>{`retry`}</text>{`
  `}<text parentName="svg" {...{
          "x": "434",
          "y": "108"
        }}>{`session`}</text>{`
  `}<text parentName="svg" {...{
          "x": "498",
          "y": "108"
        }}>{`deadline`}</text>{`
  `}<path parentName="svg" {...{
          "d": "M 560,96 A 16,16 0,0,1 560,112",
          "className": "nofill"
        }}></path>{`
  `}<text parentName="svg" {...{
          "x": "306",
          "y": "140"
        }}>{`retrying`}</text>{`
  `}<text parentName="svg" {...{
          "x": "378",
          "y": "140"
        }}>{`at`}</text>{`
  `}<text parentName="svg" {...{
          "x": "402",
          "y": "140"
        }}>{`this`}</text>{`
  `}<text parentName="svg" {...{
          "x": "442",
          "y": "140"
        }}>{`point`}</text>{`
  `}<g parentName="svg">{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "16",
            "x2": "4",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "40",
            "x2": "580",
            "y2": "40",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "580",
            "y1": "40",
            "x2": "580",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "100",
            "y1": "16",
            "x2": "100",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "196",
            "y1": "16",
            "x2": "196",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "388",
            "y1": "16",
            "x2": "388",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "4",
            "y1": "72",
            "x2": "580",
            "y2": "72",
            "className": "solid"
          }}></line>{`
    `}<line parentName="g" {...{
            "x1": "332",
            "y1": "72",
            "x2": "332",
            "y2": "96",
            "className": "solid"
          }}></line>{`
  `}</g>
      </svg>
    </span>
    <p>{`Unlike the example above, the `}<a parentName="p" {...{
        "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
      }}>{`type://Backoff`}</a>{` is enabled and it makes the `}<a parentName="p" {...{
        "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
      }}>{`type://RetryingClient`}</a>{` perform
retries with 3-second delay. When the second attempt is finished at 9,000ms, the next attempt will be
at 12,000ms exceeding the response timeout of 10,000ms.
The `}<a parentName="p" {...{
        "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
      }}>{`type://RetryingClient`}</a>{`, at this point, stops retrying and finished the retry session with the last
received `}<a parentName="p" {...{
        "href": "type://Response:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Response.html"
      }}>{`type://Response`}</a>{`, retrieved at 9,000ms from the attempt 2.`}</p>
    <h2 {...{
      "id": "retryingclient-with-logging",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#retryingclient-with-logging",
        "aria-label": "retryingclient with logging permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h2">{`RetryingClient`}</inlineCode>{` with logging`}</h2>
    <p>{`You can use `}<a parentName="p" {...{
        "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
      }}>{`type://RetryingClient`}</a>{` with `}<a parentName="p" {...{
        "href": "type://LoggingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/logging/LoggingClient.html"
      }}>{`type://LoggingClient`}</a>{` to log. If you want to log all of the
requests and responses, decorate `}<a parentName="p" {...{
        "href": "type://LoggingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/logging/LoggingClient.html"
      }}>{`type://LoggingClient`}</a>{` with `}<a parentName="p" {...{
        "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
      }}>{`type://RetryingClient`}</a>{`. That is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`RetryRule rule = RetryRule.failsafe();
WebClient client = WebClient.builder(...)
                            .decorator(LoggingClient.newDecorator())
                            .decorator(RetryingClient.newDecorator(rule))
                            .build();
`}</code></pre>
    <p>{`This will produce following logs when there are three attempts:`}</p>
    <pre><code parentName="pre" {...{}}>{`Request: {startTime=..., length=..., duration=..., scheme=..., host=..., headers=[...]
Response: {startTime=..., length=..., duration=..., headers=[:status=500, ...]
Request: {startTime=..., ..., headers=[..., armeria-retry-count=1, ...]
Response: {startTime=..., length=..., duration=..., headers=[:status=500, ...]
Request: {startTime=..., ..., headers=[..., armeria-retry-count=2, ...]
Response: {startTime=..., length=..., duration=..., headers=[:status=200, ...]
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`Did you notice that the `}<inlineCode parentName="p">{`armeria-retry-count`}</inlineCode>{` header is inserted from the second request?
`}<a parentName="p" {...{
          "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
        }}>{`type://RetryingClient`}</a>{` inserts it to indicate the retry count of a request.
The server might use this value to reject excessive retries, etc.`}</p>
    </Tip>
    <p>{`If you want to log the first request and the last response, no matter if it's successful or not,
do the reverse:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.logging.LoggingClient;

RetryRule rule = RetryRule.failsafe();
// Note the order of decoration.
WebClient client = WebClient.builder(...)
                            .decorator(RetryingClient.newDecorator(rule))
                            .decorator(LoggingClient.newDecorator())
                            .build();
`}</code></pre>
    <p>{`This will produce single request and response log pair and the total number of attempts only, regardless
how many attempts are made:`}</p>
    <pre><code parentName="pre" {...{}}>{`Request: {startTime=..., length=..., duration=..., scheme=..., host=..., headers=[...]
Response: {startTime=..., length=..., headers=[:status=200, ...]}, {totalAttempts=3}
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`Please refer to `}<a parentName="p" {...{
          "href": "/docs/advanced-structured-logging#nested-log"
        }}>{`Nested log`}</a>{`,
if you are curious about how this works internally.`}</p>
    </Tip>
    <h2 {...{
      "id": "retryingclient-with-circuit-breaker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#retryingclient-with-circuit-breaker",
        "aria-label": "retryingclient with circuit breaker permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h2">{`RetryingClient`}</inlineCode>{` with circuit breaker`}</h2>
    <p>{`You might want to use `}<a parentName="p" {...{
        "href": "/docs/client-circuit-breaker"
      }}>{`Circuit breaker`}</a>{` with `}<a parentName="p" {...{
        "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
      }}>{`type://RetryingClient`}</a>{` using
`}<a parentName="p" {...{
        "href": "/docs/client-decorator"
      }}>{`Decorating a client`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.circuitbreaker.CircuitBreakerRule;
import com.linecorp.armeria.client.circuitbreaker.CircuitBreakerClientBuilder;

CircuitBreakerRule cbRule = CircuitBreakerRule.onServerErrorStatus();
RetryRule myRetryRule = RetryRule.builder()
                                 ...
                                 .build();

WebClient client = WebClient.builder(...)
                            .decorator(CircuitBreakerClient.builder(cbRule)
                                                           .newDecorator())
                            .decorator(RetryingClient.builder(myRetryRule)
                                                     .newDecorator())
                            .build();

AggregatedHttpResponse res = client.execute(...).aggregate().join();
`}</code></pre>
    <p>{`This decorates `}<a parentName="p" {...{
        "href": "type://CircuitBreakerClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerClient.html"
      }}>{`type://CircuitBreakerClient`}</a>{` with `}<a parentName="p" {...{
        "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
      }}>{`type://RetryingClient`}</a>{` so that the `}<a parentName="p" {...{
        "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
      }}>{`type://CircuitBreaker`}</a>{`
judges every request and retried request as successful or failed. If the failure rate exceeds a certain
threshold, it raises a `}<a parentName="p" {...{
        "href": "type://FailFastException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/FailFastException.html"
      }}>{`type://FailFastException`}</a>{`. When using both clients, you need to build a custom
`}<a parentName="p" {...{
        "href": "type://RetryRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRule.html"
      }}>{`type://RetryRule`}</a>{` to handle this exception so that the `}<a parentName="p" {...{
        "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
      }}>{`type://RetryingClient`}</a>{` does not attempt
a retry unnecessarily when the circuit is open, e.g.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.circuitbreaker.FailFastException;

RetryRule.of(RetryRule.builder()
                      // The circuit is already open so stops retrying.
                      .onException(FailFastException.class)
                      .thenNoRetry(),
             RetryRule.builder()
                      .onException(ex -> ex instanceof ResponseTimeoutException ||
                                         ex instanceof UnprocessedRequestException)
                      .thenBackoff(),
             // Implement the rest of your own rule.
             ...);
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`You may want to allow retrying even on `}<a parentName="p" {...{
          "href": "type://FailFastException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/FailFastException.html"
        }}>{`type://FailFastException`}</a>{` when your endpoint is configured with
client-side load balancing because the next attempt might be sent to the next available endpoint.
See `}<a parentName="p" {...{
          "href": "/docs/client-service-discovery"
        }}>{`Client-side load balancing and service discovery`}</a>{`
for more information.`}</p>
    </Tip>
    <h2 {...{
      "id": "see-also",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#see-also",
        "aria-label": "see also permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`See also`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/advanced-structured-logging"
        }}>{`Structured logging`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      